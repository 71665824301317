"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HackTableFiltersStyling = exports.HackTableHeaderStyling = exports.OrderNumberCell = exports.TableFiltersContainer = exports.LoadMoreButtonContainer = exports.StyledRightAlignedTableCell = exports.StyledHeaderContainer = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const polaris_1 = require("@shopify/polaris");
exports.StyledHeaderContainer = styled_components_1.default.div `
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;
exports.StyledRightAlignedTableCell = styled_components_1.default.div `
  display: flex;
  justify-content: flex-end;
`;
exports.LoadMoreButtonContainer = styled_components_1.default.div `
  display: flex;
  justify-content: center;
  padding: 1rem;
  width: 100%;
`;
exports.TableFiltersContainer = styled_components_1.default.div `
  padding: 1rem;
  display: flex;

  > div {
    flex: 1;
  }
`;
exports.OrderNumberCell = (0, styled_components_1.default)(polaris_1.IndexTable.Cell) `
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0.7rem !important;

  .problem-order-icon {
    & + span {
      margin-left: 0.3rem;
    }

    visibility: ${({ isProblemOrder }) => isProblemOrder ? 'visible' : 'hidden'};
  }

  .Polaris-Icon {
    margin: 0;
  }
`;
exports.HackTableHeaderStyling = (0, styled_components_1.createGlobalStyle) `
.orders th.Polaris-IndexTable__TableHeading--second {
  padding-left: 2.2rem !important;
}
`;
exports.HackTableFiltersStyling = (0, styled_components_1.createGlobalStyle) `
.Polaris-LegacyFilters__FiltersContainerFooter {
  display: none !important;
}
`;
